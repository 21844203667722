import { useShopHandlers } from "./useShopHandlers";
import { useSearchParams } from "./useSearchParams";
import { useShopContext } from "../context/ShopContext/ShopContext";
import { ProductTaxonomyEnum } from "../../types/generalTypes";
import { useAttributeFiltersQuery } from "./useAttributeFiltersQuery";
import { useQuery } from "react-apollo";
import * as shopQueries from "../../queries/shop";
import { ShopSelectOptions } from "../../components/Shop/ui/ShopSelect/ShopSelect";
import gql from "graphql-tag";

export const getTaxonomyByShopType = (type?: "category" | "brand") => {
  switch (type) {
    case "category":
      return "PRODUCTCATEGORY";
    case "brand":
      return "PRODUCTBRAND";
    default:
      return "PRODUCTCATEGORY";
  }
};

export const getProductCategories = gql`
  query getProductCategories($customTaxonomy: [ChosenAttributesInput]) {
    productCategories(
      first: 100
      where: {
        hideEmpty: true
        exclude: "15"
        customTaxonomy: $customTaxonomy
        parent: 0
      }
    ) {
      nodes {
        id
        link
        name
        slug
        image {
          databaseId
          mediaItemUrl
          sourceUrl
        }
        description
        children(first: 100, where: { hideEmpty: true, customTaxonomy: $customTaxonomy }) {
          nodes {
            id
            name
            link
            slug
            image {
              databaseId
              mediaItemUrl
              sourceUrl
            }
            description
            children(
              where: { hideEmpty: true, customTaxonomy: $customTaxonomy }
            ) {
              nodes {
                id
                name
                link
                slug
                image {
                  databaseId
                  mediaItemUrl
                  sourceUrl
                }
                description
                children(
                  where: { hideEmpty: true, customTaxonomy: $customTaxonomy }
                ) {
                  nodes {
                    id
                    name
                    link
                    slug
                    image {
                      databaseId
                      mediaItemUrl
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface ShopProductCategoryCustom {
  id: string;
  link: string;
  slug: string;
  name: string;
  description: string;
  image: {
    id: string;
    sourceUrl: string;
    databaseId: number;
    mediaItemUrl: string;
  };
  children: {
    nodes: ShopProductCategoryCustom[];
  };
}

export interface GetShopProductCategoriesResponseCustom {
  productCategories: {
    nodes: ShopProductCategoryCustom[];
  };
}

export const useShopFilterData = () => {
  const shopCtx = useShopContext();
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const taxonomies = shopHandlers.prepareTaxonomyFilters(searchParams);
  const brand = searchParams["brand"];

  const taxonomy = getTaxonomyByShopType(
    shopCtx?.data?.shopType
  ) as ProductTaxonomyEnum;

  const attributes = useAttributeFiltersQuery(
    shopCtx?.data?.slug
      ? {
          taxonomy: taxonomy,
          terms: shopCtx?.data?.slug,
        }
      : undefined
  );

  const { loading, data, error, refetch: refetchProductCategories } = useQuery<
    GetShopProductCategoriesResponseCustom,
    shopQueries.GetShopProductCategoriesVariables
  >(getProductCategories, {
    variables: {
      customTaxonomy: [
        ...taxonomies,
        {
          taxonomy: "PRODUCTBRAND" as ProductTaxonomyEnum,
          terms: [brand as string],
        },
      ],
    },
  });

  const handleAttributesOnChange = (
    attribute: string,
    selectedValue?: ShopSelectOptions
  ) => {
    shopHandlers.updateSearchParameters(
      selectedValue?.value || "",
      attribute,
      (data: any) => {
        const taxonomies = shopHandlers.prepareTaxonomyFilters(data);
        refetchProductCategories({
          customTaxonomy: taxonomies,
        });
        if (shopCtx?.handlers?.handleAttributeChange) {
          shopCtx.handlers.handleAttributeChange(data);
        }
      }
    );
  };

  const handleCategoryChange = (categorySlug: string) => {
    if (shopCtx.handlers?.handleCategoryChange) {
      shopCtx.handlers.handleCategoryChange(categorySlug);
    }
  };

  const categories = data?.productCategories?.nodes ?? [];

  const categoryOptions = categories?.map((cat) => ({
    value: cat.slug,
    label: cat.name,
    icon: cat.image?.mediaItemUrl,
    description: cat.description,
  })) as ShopSelectOptions[];

  const selectedCategory = categoryOptions.find(
    (c) => c.value === shopCtx?.data?.category
  );

  const subCategoryOptions = shopCtx?.data?.category
    ? categories
        .find((cat) => cat.slug === shopCtx?.data?.category)
        ?.children?.nodes?.map((cat) => ({
          value: cat.slug,
          label: cat.name,
          icon: cat.image?.mediaItemUrl,
          description: cat?.description,
        }))
    : [];

  const subSubCategoryOptions = shopCtx?.data?.category
    ? categories
        .find((cat1) => cat1.slug === shopCtx?.data?.category)
        ?.children?.nodes?.find(
          (cat2) => cat2.slug === shopCtx?.data?.subCategory
        )
        ?.children?.nodes?.map((cat) => ({
          value: cat.slug,
          label: cat.name,
          icon: cat.image?.mediaItemUrl,
          description: cat?.description,
        }))
    : [];

  const subSubSubCategoryOptions = shopCtx?.data?.category
    ? categories
        .find((cat1) => cat1.slug === shopCtx?.data?.category)
        ?.children?.nodes?.find(
          (cat2) => cat2.slug === shopCtx?.data?.subCategory
        )
        ?.children?.nodes?.find(
          (cat3) => cat3.slug === shopCtx?.data?.subSubCategory
        )
        ?.children?.nodes?.map((cat4) => ({
          value: cat4.slug,
          label: cat4.name,
          icon: cat4.image?.mediaItemUrl,
          description: cat4?.description,
        }))
    : [];

  const selectedSubCategory = subCategoryOptions?.find(
    (sc) => sc.value === shopCtx?.data?.subCategory
  );

  const selectedSubSubCategory = subSubCategoryOptions?.find(
    (sc) => sc.value === shopCtx?.data?.subSubCategory
  );

  const selectedSubSubSubCAtegory = subSubSubCategoryOptions?.find(
    (sc) => sc.value === shopCtx?.data?.subSubSubCategory
  );

  const redirectLinkPrefix = shopCtx?.data?.shopLinkPrefix
    ? `${shopCtx?.data?.shopLinkPrefix}`
    : "/shop";

  const attributesData = attributes?.data?.attributeFilters ?? [];

  return {
    error,
    loading,
    categories,
    attributes,
    attributesData,
    categoryOptions,
    selectedCategory,
    redirectLinkPrefix,
    subCategoryOptions: {
      subCategoryOptions: subCategoryOptions,
      description: subCategoryOptions ? subCategoryOptions[0]?.description : [],
    },
    selectedSubCategory,
    handleCategoryChange,
    subSubCategoryOptions: {
      subSubCategoryOptions: subSubCategoryOptions,
      description: subCategoryOptions ? subCategoryOptions[0]?.description : [],
    },
    selectedSubSubCategory,
    subSubSubCategoryOptions: {
      description: subCategoryOptions ? subCategoryOptions[0]?.description : [],
      subSubSubCategoryOptions: subSubSubCategoryOptions,
    },
    handleAttributesOnChange,
    selectedSubSubSubCAtegory,
  };
};
